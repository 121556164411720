.containerCongratsText {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
}

.containerCongratsText h1 {
  background-color: #00000088;
  padding: 10px;
  border-radius: 10px;
  color: white;
}
