.containerTeamSelect {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.containerTeamsSelect {
  border-radius: 20px;
}
