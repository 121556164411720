.containerTeam {
  margin: 30px;
  border-radius: 20px;
  text-align: center;
  padding: 20px 40px;
}

.teamSeatsText {
  font-size: 16px;
  font-weight: 600;
}

.teamImage {
  height: 150px;
}

@media only screen and (max-width: 740px) {
  .containerTeam {
    margin: 20px;
    padding: 15px 30px;
  }

  .teamSeatsText {
    font-size: 14px;
    
  }

  .teamImage {
    height: 100px;
  }
}

@media only screen and (max-width: 460px) {
  .containerTeam {
    margin: 15px;
    padding: 10px 25px;
  }

  .teamSeatsText {
    font-size: 12px;
  }

  .teamName {
    font-size: 1em;
  }

  .teamImage {
    height: 80px;
  }
}