.containerTeamSelect {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.containerWinnerBanner {
  border-radius: 20px;
  height: 50%;
  flex-direction: column;
}

.titleWinnerBanner {
  color: #fff;
  margin-top: 40px;
  margin-bottom: -30px;
  padding: 0 40px;
  text-align: center;
}
