.containerBanner {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerTypeGame {
  background-color: #ffffff9a;
  width: 45%;
  height: 25px;
  margin: 0 auto;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.containerTeams {
  background-color: #0000009a;
  margin: 0 auto;
  border-top-left-radius: 20;
  border-top-right-radius: 20;
  width: 75%;
  height: 40%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.vsText {
  color: #fff;
  font-size: 40px;
}

.containerStadiumName {
  background-color: #ffffff9a;
  width: 75%;
  height: 25px;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.titleBanner {
  background-color: #00000088;
  padding: 8px 15px;
  border-radius: 10px;
  color: white;
}

@media only screen and (max-width: 740px) {
  .titleBanner {
    font-size: 1.8em;
    text-align: center;
  }

  .containerTeams {
    height: 30%;
  }

  .vsText {
    font-size: 30px;
  }
}
@media only screen and (max-width: 640px) {
  .containerTypeGame {
    width: 50%;
  }

  .containerTeams {
    width: 85%;
    height: 30%;
  }

  .containerStadiumName {
    width: 85%;
  }
}
@media only screen and (max-width: 560px) {
  .titleBanner {
    font-size: 1.5em;
    text-align: center;
  }

  .containerTypeGame {
    width: 55%;
  }

  .containerTeams {
    width: 100%;
    height: 30%;
  }

  .containerStadiumName {
    width: 100%;
  }

  .MuiButton-root {
    font-size: 11px !important;
  }
}
